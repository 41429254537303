@import "../variables";

.banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    margin: 0 auto;
    margin-top: 0.46rem;
    padding-bottom: 0.46rem;
    max-width: 1032px;
    font-family: 'Titillium Web', sans-serif;
    border-bottom-style: solid;
    border-bottom-color: $color07;

    &__svg {
      &Gradient1 {
        stop-opacity: 1;
        stop-color: #def6ff;
      }

      &Gradient2 {
        stop-opacity: 1;
        stop-color: #f7f7f7;
      }
    }

    &__title {
      margin: 1rem 0rem 0rem 0rem;
      color: $color10;
    }

    &__info {
      color: $color10;
    }

    .animArrowDown {
        animation: animArrowDown__keyframes 3000ms linear infinite normal forwards;
    }
      
    @keyframes animArrowDown__keyframes {
        5%  {fill: $color07}
        10% {fill: $color10}
        35% {fill: $color10}
        40% {fill: $color07}
        45% {fill: $color10}
        85% {fill: $color10}
        90% {fill: $color07}
        100% {fill: $color10}
    }
      
    .animArrowUp {
        animation: animArrowUp__keyframes 2000ms linear infinite normal forwards;
    }
      
    @keyframes animArrowUp__keyframes {
        5%  {fill: $color07}
        10% {fill: $color12}
        15% {fill: $color07}
        20% {fill: $color12}
        25% {fill: $color07}
        30% {fill: $color12}
        100% {fill: $color12}
    }
      
    .animModem1Snd {
        animation: animModem1Snd__keyframes 2000ms linear infinite normal forwards;
    }
      
    @keyframes animModem1Snd__keyframes {
        5%  {fill: $colorModemSnd}
        10% {fill: $color00}
        45% {fill: $color00}
        50% {fill: $colorModemSnd}
        55% {fill: $color00}
        70% {fill: $color00}
        75% {fill: $colorModemSnd}
        100% {fill: $color00}
    }
      
    .animModem2Rcv {
        animation: animModem2Rcv__keyframes 2000ms linear infinite normal forwards;
    }
      
    @keyframes animModem2Rcv__keyframes {
        5%  {fill: $colorModemRcv}
        10% {fill: $color00}
        45% {fill: $color00}
        50% {fill: $colorModemRcv}
        55% {fill: $color00}
        70% {fill: $color00}
        75% {fill: $colorModemRcv}
        100% {fill: $color00}
    }
      
    .animModem2Snd {
        animation: animModem2Snd__keyframes 3000ms linear infinite normal forwards;
    }
      
    @keyframes animModem2Snd__keyframes {
        5%  {fill: $colorModemSnd}
        10% {fill: $color00}
        45% {fill: $color00}
        50% {fill: $colorModemSnd}
        55% {fill: $color00}
        70% {fill: $color00}
        75% {fill: $colorModemSnd}
        100% {fill: $color00}
    }
      
    .animModem1Rcv {
        animation: animModem1Rcv__keyframes 3000ms linear infinite normal forwards;
    }
      
    @keyframes animModem1Rcv__keyframes {
        5%  {fill: $colorModemRcv}
        10% {fill: $color00}
        45% {fill: $color00}
        50% {fill: $colorModemRcv}
        55% {fill: $color00}
        70% {fill: $color00}
        75% {fill: $colorModemRcv}
        100% {fill: $color00}
    }
}

.content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1032px;
    border-bottom-style: solid;
    border-bottom-color: $color07;
    margin-top: 0.4em;
    padding-bottom: 0.4em;
    
    &__article {
        flex: 1 0 40%;
        margin: 0.5rem;
        padding: 1.5em 2em 1em 2em;
        height: 20em;

        img {
            margin-top: 2.3rem;
        }
      
        svg {
            padding: 0 1em 0 0;
            vertical-align: middle;
        }
      
        h3 {
            color: $color00;
            display: inline-block;
            vertical-align: middle;
        }
      
        p {
            flex: 100%;
            text-align: justify;
            text-indent: 3%;
            line-height: 1.4rem;
            padding-top: 1rem;
        }          

        &--background-1 {
            background-image: url('../../images/background-1.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        &--background-2 {
            background-image: url('../../images/background-2.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        &--background-3 {
            background-image: url('../../images/background-3.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        &--background-4 {
            background-image: url('../../images/background-4.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }

        &--microsoft365 {
            margin-top: 0.5rem;
            font-size: 0.7em;
        }
        &--NetCore {
            padding: 0rem 0.5rem !important;
        }
    }
}

.authorizations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    max-width: 1032px;

    h3 {
        flex: 0 1 100%;
        padding: 1rem 0rem 0rem 1rem;
        color: $color00;
        display: inline-block;
        vertical-align: middle;
    }

    img {
        padding: 2rem 1rem 2rem 1rem;
    }

    &__Microsoft {
        height: 80px;
    }

    &__VMWare {
        height: 80px;
    }

    &__Veeam {
        height: 60px;
    }

    &__ESET {
        height: 50px;
    }

    &__KiR {
        height: 60px;
    }

    &__InsERT {
        height: 40px;
    }
}