@import "../variables";

.implementations {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2rem auto;
    max-width: 1000px;
    background-color: $color10;

    &__table {
        width: 100%;
    }

    &__company {
        width: 25%;
    }

    &__detail {
        width: 65%;
    }

    &__date {
        width: 10%;
    }

    table {
        border-collapse: collapse;
    }

    th {
        padding: 1em;
        border: 0.1em solid $color10;
        background-color: #e6f8ff;
        border: 1px solid rgb(221, 221, 221);
    }

    tr {
        transition: 0.2s;
    }

    tr td:first-child {
        text-align: center;
    }

    tr td:last-child {
        text-align: center;
    }
    
    tr:hover {
        background-color: $color09;
        transform: scale(1.01); 
    }

    td {
        padding: 0.6em;
        border: 0.1em solid rgb(228, 228, 228);
    }
}
