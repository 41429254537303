@import "../styles/variables";

footer {
    margin: 0 auto;
    max-width: 1032px;
}

footer a {
    text-decoration: none;
    color: $color00;
}
a:hover {
    text-decoration: underline;
}

.social {
    margin-top: 1rem;
    background-color: $color10;

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-style: solid;
        border-top-color: $color07;
    }
    li {
        display: inline-block;
        margin: 1.25rem 0.75rem 1.25rem 0;
        font-size: 1.25rem;
    }
}

.legal {
    background-color: $color09;
    font-size: 0.8rem;

    ul {
        display: flex;
        width: 100%;
        list-style: none;
        align-items: center;
        height: 3rem;
    }

    li {
        margin: 0.5rem 1rem 0.5rem 1rem;
        
        a {
            display: flex;
            align-items: center;
            height: 100%;
            text-decoration: none;

            svg {
                margin: 0 0 0.1rem 0.2rem;
             }
         }
    }

    li:nth-last-child(2) {
        flex: 1;
    }

    img {
        height: 20px;
        margin: 0.5rem 1rem 0rem 1rem;
    }
}

.iconLinkedinFill {
    .iconLinkedinFill1 {
        fill: $color10;
    }
    
    .iconLinkedinFill2 {
        fill: $color00;
    }
    
    &:hover {
        .iconLinkedinFill2 {
            fill: #3663AB;
        }
    }
}

.iconGitHUBFill {
    iconGitHUBFill1 {
        fill: #2F2C2A;
    }
}

.iconYoutubeFill {
    .iconYoutubeFill1 {
        fill: $color00;
        fill-rule:nonzero;
    }
    
    .iconYoutubeFill2 {
        fill: $color10;
        fill-rule:nonzero;
    }

    &:hover {
        .iconYoutubeFill1 {
            fill:red;
            fill-rule:nonzero;
        }
    }
}

.iconFacebookFill {
    .iconFacebookFill1 {
        fill: $color00;
        fill-rule:nonzero;
    }
    
    .iconFacebookFill2 {
        fill: $color10;
        fill-rule:nonzero;
    }

    &:hover {
        .iconFacebookFill1 {
            fill:#1877F2;
            fill-rule:nonzero;
        }
    }
}

.iconMailFill {
    .iconMailFill1 {
        fill: $color10;
    }
    
    .iconMailFill2 {
        fill: $color00;
    }

    &:hover {
        .iconMailFill2 {
            fill : #ffb300;
        }
    }
}
